import { APIFilter, API_FILTER_OPERATORS } from '@/utils/api'
import { currentDate } from '@/utils/date'

export default {
  async selectLookups (Vue) {
    const apiFilterPlantillaArticulo = new APIFilter()
      .addGT('estado', 0)
    const apiFilterInicio = new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addLTE('finicio', currentDate())
      .addIsNull('finicio')
    const apiFilterFin = new APIFilter()
      .setOperator(API_FILTER_OPERATORS.OR)
      .addGTE('ffin', currentDate())
      .addIsNull('ffin')
    apiFilterPlantillaArticulo
      .addNestedFilter(apiFilterInicio)
      .addNestedFilter(apiFilterFin)
    let apiCalls = []
    apiCalls.push({
      name: 'selectPlantillaArticulo',
      method: 'plantillaArticulo.select',
      params: { filter: apiFilterPlantillaArticulo }
    })
    return await Vue.$api.batchCall(apiCalls)
  },
}
