<template>
  <b10-base>
    <ValidationProvider
      v-slot="{ errors }"
      name="plantilla_articulo"
      :rules="formRules.plantilla_articulo"
    >
      <b10-autocomplete
        v-model="form.plantilla_articulo"
        :items="plantillasArticulos"
        item-text="descripcion"
        label="Plantilla de artículos, cuotas y revisiones"
        clearable
        :error-messages="errors"
        return-object
        no-filter
        @change="changePlantillaArticulo"
      >
        <template #item="slotProps">
          <v-list-item-content>
            <v-list-item-title>{{ $online.plantillaArticulo.title(slotProps.item) }}</v-list-item-title>
            <v-list-item-subtitle>{{ $online.plantillaArticulo.subtitle(slotProps.item) }}</v-list-item-subtitle>
          </v-list-item-content>
        </template>
      </b10-autocomplete>
    </ValidationProvider>
    <div
      v-for="(tipoPlantilla, index) in formGroupsLineasPlantilla"
      :key="index"
    >
      <b10-subheader
        v-show="tipoPlantilla.label"
        :label="tipoPlantilla.label"
        :icon="tipoPlantilla.icon"
      />
      <div
        v-if="tipoPlantilla.formFields"
      >
        <div
          v-for="(field, index) in tipoPlantilla.formFields"
          :key="index"
        >
          <v-checkbox
            :key="field.idlplantilla"
            v-model="form[field.fieldName]"
            :disabled="field.obligatorio"
            :label="field.title"
            :persistent-hint="field.obligatorio"
            :hint="field.obligatorio ? 'Obligatorio' : ''"
          />
        </div>
      </div>
      <div
        v-else
      >
        <div
          v-for="(group, index) in tipoPlantilla.groups"
          :key="index"
          class="pa-2"
        >
          <b10-subheader
            :key="group.idgrupo"
            :label="group.groupName"
            :icon="group.icon"
          />
          <div
            v-for="(field, index) in group.formFields"
            :key="index"
          >
            <v-checkbox
              :key="field.idlplantilla"
              v-model="form[field.fieldName]"
              :disabled="field.obligatorio"
              :label="field.title"
              :persistent-hint="field.obligatorio"
              :hint="field.obligatorio ? 'Obligatorio' : ''"
            />
          </div>
        </div>
      </div>
    </div>
  </b10-base>
</template>

<script>
import formMixin from '@/mixins/formMixin'
import Data from './PlantillaArticuloAddFormData'

export default {
  mixins: [formMixin],
  data () {
    return {
      form: {
        plantilla_articulo: null,
      },
      formRules: {
        plantilla_articulo: { required: true },
      },
      plantillasArticulos: [],
      formGroupsLineasPlantilla: []
    }
  },
  async created () {
    const resp = await Data.selectLookups(this)
    this.plantillasArticulos = resp.data.selectPlantillaArticulo.result.dataset
    await this.loadForm()
  },
  methods: {
    async loadFormData () {
      // no tiene posibilidad de edicion
    },
    borrarFormFields (linea) {
      for (const formField of linea.formFields) {
        delete this.form[formField.fieldName]
      }
    },
    borrarPlantillaFormFields () {
      // borrar campos del form de las plantillas
      for (const grupoPlantilla in this.formGroupsLineasPlantilla) {
        if (this.formGroupsLineasPlantilla[grupoPlantilla].formFields) {
          this.borrarFormFields(this.formGroupsLineasPlantilla[grupoPlantilla])
        } else if (this.formGroupsLineasPlantilla[grupoPlantilla].groups){
          for (const group of this.formGroupsLineasPlantilla[grupoPlantilla].groups) {
            this.borrarFormFields(group)
          }
        }
      }
      this.formGroupsLineasPlantilla = []
    },
    async changePlantillaArticulo (value) {
      this.borrarPlantillaFormFields()
      if (value) {
        this.formGroupsLineasPlantilla = {
          lineasPlantillaArticulos: [],
          lineasPlantillaCuotas: [],
          lineasPlantillaMant: [],
        }
        const resp = await this.$online.plantillaArticulo.selectLineasPlantilla(value.idplantilla_articulo)
        // Form dinámico
        let linea = { idlplantilla: -1}
        let lastGrupo = { idgrupo: -1 }
        const lineasPlantillaArticulo = resp.data.selectLplantillaArticulo.result.dataset
        if (lineasPlantillaArticulo.length > 0) {
          this.formGroupsLineasPlantilla.lineasPlantillaArticulos = {
            label: 'Artículos',
            icon: '$vuetify.icons.values.lpresupuestocli',
            groups: []
          }
          for (const lineaPlantillaArticulo of lineasPlantillaArticulo) {
            if (lineaPlantillaArticulo.idplantilla_grupo_articulo !== lastGrupo.idgrupo){
              lastGrupo = {
                idgrupo: lineaPlantillaArticulo.idplantilla_grupo_articulo,
                groupName: lineaPlantillaArticulo.grupo_articulo_desc,
                icon: '$vuetify.icons.values.articulo',
                formFields: []
              }
              this.formGroupsLineasPlantilla.lineasPlantillaArticulos.groups.push(lastGrupo)
            }
            if (lineaPlantillaArticulo.idlplantilla_articulo !== linea.idlplantilla) {
              const fieldnameLplantillaArticulo = `lplantilla_articulo_${lineaPlantillaArticulo.idlplantilla_articulo}`
              linea = {
                title: this.$online.lplantillaArticulo.title(lineaPlantillaArticulo),
                idlplantilla: lineaPlantillaArticulo.idlplantilla_articulo,
                incluir: lineaPlantillaArticulo.incluir_por_defecto,
                obligatorio: lineaPlantillaArticulo.obligatorio,
                fieldName: fieldnameLplantillaArticulo,
              }
              lastGrupo.formFields.push(linea)
              this.$set(this.form, fieldnameLplantillaArticulo, lineaPlantillaArticulo.incluir_por_defecto)
            }
          }
        } else {
          delete this.formGroupsLineasPlantilla.lineasPlantillaArticulos
        }
        const lineasPlantillaCuotas = resp.data.selectLplantillaCuota.result.dataset
        if (lineasPlantillaCuotas.length > 0) {
          this.formGroupsLineasPlantilla.lineasPlantillaCuotas = {
            label: 'Cuotas',
            icon: '$vuetify.icons.values.cuotas',
            formFields: []
          }
          for (const lineaPlantillaCuota of lineasPlantillaCuotas) {
            const fieldnameLplantillaCuota = `lplantilla_cuota_${lineaPlantillaCuota.idlplantilla_cuota}`
            linea = {
              title: this.$online.lplantillaCuota.title(lineaPlantillaCuota),
              idlplantilla: lineaPlantillaCuota.idlplantilla_cuota,
              incluir: lineaPlantillaCuota.incluir_por_defecto,
              obligatorio: lineaPlantillaCuota.obligatorio,
              fieldName: fieldnameLplantillaCuota,
            }
            this.formGroupsLineasPlantilla.lineasPlantillaCuotas.formFields.push(linea)
            this.$set(this.form, fieldnameLplantillaCuota, lineaPlantillaCuota.incluir_por_defecto)
          }
        } else {
          delete this.formGroupsLineasPlantilla.lineaPlantillaCuota
        }
        const lineasPlantillaMant = resp.data.selectLplantillaMant.result.dataset
        if (lineasPlantillaMant.length > 0) {
          this.formGroupsLineasPlantilla.lineasPlantillaMant = {
            label: 'Revisiones de sistema',
            icon: '$vuetify.icons.values.revision',
            formFields: []
          }
          for (const lineaPlantillaMant of lineasPlantillaMant) {
            const fieldnameLplantillaMant = `lplantilla_mant_${lineaPlantillaMant.idlplantilla_mant}`
            linea = {
              title: this.$online.lplantillaMant.title(lineaPlantillaMant),
              idlplantilla: lineaPlantillaMant.idlplantilla_mant,
              incluir: lineaPlantillaMant.incluir_por_defecto,
              obligatorio: lineaPlantillaMant.obligatorio,
              fieldName: fieldnameLplantillaMant,
            }
            this.formGroupsLineasPlantilla.lineasPlantillaMant.formFields.push(linea)
            this.$set(this.form, fieldnameLplantillaMant, lineaPlantillaMant.incluir_por_defecto)
          }
        } else {
          delete this.formGroupsLineasPlantilla.lineasPlantillaMant
        }
      }
    }
  },
}
</script>
